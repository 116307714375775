export const featuredPortfolio = [
  {
    id: 1,
    title: "Social",
    img: "assets/portfolio/social_profile.png",
    source: "https://github.com/gloria-cheung/social",
    gif: "assets/portfolio/social_overview.gif",
    desc: "Social is a social networking app that allows users to connect and share posts with friends and family. This full stack app was created with ReactJS for client side and NodeJS, Express and MongoDB for server side.",
  },
  {
    id: 2,
    title: "Home Finder",
    img: "assets/portfolio/homefinder.png",
    source: "https://github.com/gloria-cheung/home_finder",
    gif: "assets/portfolio/homefinder_overview.gif",
    desc: "Home Finder is an app created with Node.js and React that allows users to view homes for sale or rent and to connect with listing agents to their sell existing homes. Twilio and Zillow APIs are used to fetch live data.",
  },
  {
    id: 3,
    title: "GloriasPlantsAndPots",
    img: "assets/portfolio/gloriasplantsandpotsapp.png",
    source: "https://github.com/gloria-cheung/gloriasplantsandpots",
    gif: "assets/portfolio/gloriasplantsandpots_overview.gif",
    desc: "Glorias Plants & Pots is an e-commerce app built with Rails 7.0 that allows logged-in users to purchase handmade pottery and see previous orders. Users with admin access can maintain storefront by adding new products, updating existing products and deleting unwanted products.",
  },
  {
    id: 4,
    title: "Scheduler",
    img: "assets/portfolio/scheduler.png",
    source: "https://github.com/gloria-cheung/scheduler",
    gif: "assets/portfolio/scheduler_overview.gif",
    desc: "Interview Scheduler is a simple, SPA that allows users to book an interview with LHL mentors that are available for the day.",
  },
  {
    id: 5,
    title: "Food Order App",
    img: "assets/portfolio/foodorder.png",
    source: "https://github.com/gloria-cheung/food-order-app",
    gif: "assets/portfolio/foodorder_overview.gif",
    desc: "A Food Order application created with Create React App where user can add/remove food items to/from cart, rendering the total amount of items in cart along with final total price. The order can then be submitted to a backend database hosted on firebase.",
  },
  {
    id: 6,
    title: "SkinLab",
    img: "assets/portfolio/skinlab.png",
    source: "https://github.com/gloria-cheung/skinlab",
    gif: "assets/portfolio/skinlab_overview.gif",
    desc: "Skinlab is a skincare e-commerce app that allows users to browse and purchase skincare products via checkout using stripe. This full stack app was created with ReactJS for client side and Flask Python and MySQL for server side.",
  },
];

export const reactPortfolio = [
  {
    id: 1,
    title: "Home Finder",
    img: "assets/portfolio/homefinder.png",
    source: "https://github.com/gloria-cheung/home_finder/",
    gif: "assets/portfolio/homefinder_overview.gif",
    desc: "Home Finder is an app created with Node.js and React that allows users to view homes for sale or rent and to connect with listing agents to their sell existing homes. Twilio and Zillow APIs are used to fetch live data.",
  },
  {
    id: 2,
    title: "Scheduler",
    img: "assets/portfolio/scheduler.png",
    source: "https://github.com/gloria-cheung/scheduler",
    gif: "assets/portfolio/scheduler_overview.gif",
    desc: "Interview Scheduler is a simple, SPA that allows users to book an interview with LHL mentors that are available for the day.",
  },
  {
    id: 3,
    title: "BakedByGloria",
    img: "assets/portfolio/bakedbygloriaapp.png",
    source: "https://github.com/gloria-cheung/bakedbygloria",
    gif: "assets/portfolio/bakedbygloria_overview.gif",
    desc: "Baked by Gloria is custom cake website built with Create React App that allows users to browse past cake creations, submit cake inquiries and view pricing lists.",
  },
  {
    id: 4,
    title: "Social",
    img: "assets/portfolio/social_profile.png",
    source: "https://github.com/gloria-cheung/social/",
    gif: "assets/portfolio/social_overview.gif",
    desc: "Social is a social networking app that allows users to connect and share posts with friends and family. This full stack app was created with ReactJS for client side and NodeJS, Express and MongoDB for server side.",
  },
  {
    id: 5,
    title: "To Do List",
    img: "assets/portfolio/todolist.png",
    source: "https://github.com/gloria-cheung/to-do-list",
    gif: "assets/portfolio/todolist_overview.gif",
    desc: "Social is a social networking app that allows users to connect and share posts with friends and family. This full stack app was created with ReactJS for client side and NodeJS, Express and MongoDB for server side.",
  },
  {
    id: 6,
    title: "Food Order App",
    img: "assets/portfolio/foodorder.png",
    source: "https://github.com/gloria-cheung/food-order-app",
    gif: "assets/portfolio/foodorder_overview.gif",
    desc: "A Food Order application created with Create React App where user can add/remove food items to/from cart, rendering the total amount of items in cart along with final total price. The order can then be submitted to a backend database hosted on firebase.",
  },
];

export const rubyPortfolio = [
  {
    id: 1,
    title: "GloriasPlantsAndPots",
    img: "assets/portfolio/gloriasplantsandpotsapp.png",
    source: "https://github.com/gloria-cheung/gloriasplantsandpots",
    gif: "assets/portfolio/gloriasplantsandpots_overview.gif",
    desc: "Glorias Plants & Pots is an e-commerce app built with Rails 7.0 that allows logged-in users to purchase handmade pottery and see previous orders. Users with admin access can maintain storefront by adding new products, updating existing products and deleting unwanted products.",
  },
  {
    id: 2,
    title: "Jungle",
    img: "assets/portfolio/jungle.png",
    source: "https://github.com/gloria-cheung/jungle-rails",
    gif: "assets/portfolio/jungle.png",
    desc: "Jungle is an e-commerce app built with Rails 6.1 that allows logged-in users to purchase plant items and see previous orders. Users with admin access can maintain storefront by adding new products, updating existing products and deleting unwanted products.",
  },
  {
    id: 3,
    title: "2 Player Math Game",
    img: "assets/portfolio/mathgame.png",
    source: "https://github.com/gloria-cheung/2player_math_game",
    gif: "assets/portfolio/mathgame.png",
    desc: "This command line game allows 2 players to practice their addition skills. With every wrong answer, player loses a life until no lives remain, making the other player the winner.",
  },
];

export const widgetPortfolio = [
  {
    id: 1,
    title: "Tweeter",
    img: "assets/portfolio/tweeter.png",
    source: "https://github.com/gloria-cheung/tweeter",
    gif: "assets/portfolio/tweeter_overview.png",
    desc: "Tweeter is a simple, single-page Twitter clone. This web application is focused on developing front end skills such as HTML, CSS, JS, jQuery and AJAX. Users are able to view current tweets and post new tweets using this app.",
  },
  {
    id: 2,
    title: "Score Keeper",
    img: "assets/portfolio/scorekeeper.png",
    source: "https://github.com/gloria-cheung/score_keeper_widget",
    gif: "assets/portfolio/scorekeeper_overview.png",
    desc: "Score Keeper is a simple widget that keeps score between two players in a game of ping pong.",
  },
  {
    id: 3,
    title: "Pokedex",
    img: "assets/portfolio/pokedex.png",
    source: "https://github.com/gloria-cheung/pokedex",
    gif: "assets/portfolio/pokedex_overview.png",
    desc: "A Pokemon application that displays winner based on total experience of Pokecards in each player's Pokedex. Each Pokedex is randomly generated with Pokecards.",
  },
  {
    id: 4,
    title: "TV Show Search",
    img: "assets/portfolio/tvshow_search.png",
    source: "https://github.com/gloria-cheung/tv_show_search",
    gif: "assets/portfolio/tvshow_search.png",
    desc: "TV Show Search widget allows users to search TV shows through fetching data from an external API and displaying the covers from the search.",
  },
  {
    id: 5,
    title: "Lights Out",
    img: "assets/portfolio/lightsout.png",
    source: "https://github.com/gloria-cheung/lightsout",
    gif: "assets/portfolio/lightsout_overview.gif",
    desc: "A lights out application created with Create React App where the goal of the game is to turn off all the lights. With every click on a block, it toggles the block and immediate neighbors on.",
  },
];
